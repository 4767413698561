.header-wrapper {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
}
.new-header {
    background: #FFF;
    box-shadow: 0px -1.0000001192092896px 0px 0px #000 inset;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr;
}
.new-header-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}
.new-menu-section {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: calc(100% - 250px);
    width: 100%;
}
.new-auth-box .black-btn {
   max-width: 118px;
}
.new-header-menu .navbar-expand-lg .navbar-nav .nav-link {
    color: var(--text-black);
    font-family: var(--roboto);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0px 16px;
}
.new-header-menu .navbar-expand-lg .navbar-nav span.nav-link {
    cursor: pointer;
}
.new-header-menu .navbar-toggler,
.new-header-menu .navbar-toggler:focus {
    background: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    width: 40px;
    height: 40px;
}
.new-header-menu .navbar-expand-lg .navbar-nav .nav-link.mobile-view-link {
    display: none;
}
.new-auth-box .btn-primary {
    background: transparent;
    color: var(--text-black);
    font-family: var(--roboto);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0px 8px;
    border: none;
}
.new-auth-box .btn-primary:hover {
    background: transparent;
    color: var(--foundation-btn-color);
}
.new-auth-box .dropdown-item { 
    color: #000 !important;
    font-family: var(--roboto);
}
  a {
    text-decoration: none !important;
  }
  
  a:hover {
    color: var(--text-black) !important;
  }
  .btn-primary {
    background: var(--foundation-violet);
    color: var(--foundation-btn-color);
    border-color: var(--foundation-violet);
    font-family: var(--assistant);
  }
  .btn:first-child:hover,
:not(.btn-check) + .btn:hover {
    background: var(--foundation-violet);
    border-color: var(--foundation-violet);
}
/*************** Media Query ******************/


@media screen and (max-width:991px) {

    .new-header-menu {
        max-width: none;
        position: absolute;
        right: 20px;
        width: auto;
    }
    .new-auth-box {
        margin-right: 67px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .new-header-menu .navbar {
        padding: 0;
        width: 40px;
        height: 40px;
    }
    .new-header-menu #responsive-navbar-nav {
        background: var(--foundation-violet);
        box-shadow: 0 4px 31px rgba(35, 40, 59, .1);
        height: 100vh;
        left: -315px;
        max-width: 315px;
        padding: 0;
        position: fixed;
        top: 0;
        transition: all .3s ease 0s;
        width: 100%;
        z-index: 999999;
    }
    .new-header-menu .navbar-collapse.collapse.show {
        left: 0 !important;
    }
    .new-header-menu .navbar-toggler.collapsed .navbar-toggler-icon {
        background-image: url("../../assets/images/new/menu-icon.svg");
        transition: all .5s;
        width: 40px;
    }
    .new-header-menu .navbar-toggler .navbar-toggler-icon {
        background-image: url("../../assets/images/new/menu-close.svg");
        width: 40px;
    }
    .navbar-nav {
        padding: 25px 20px;
    }
    .new-header-menu .navbar-expand-lg .navbar-nav .nav-link {
        color:  #E9E7EC !important;
        font-family: var(--roboto);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        border-bottom: 1px solid rgb(250 101 72);
        padding-bottom: 10px;
    }
    .new-header-menu .navbar-expand-lg .navbar-nav {
        gap: 20px;
    }
    .new-header-menu .navbar-expand-lg .navbar-nav .nav-link.mobile-view-link {
        border: none;
        display: block;
    }
    a.nav-link.mobile-view-link svg {
        width: 120px;
        height: 30px;
    }
}

@media screen and (max-width:767px) { 
    .header .logo {
        width: 90px;
    }
   
}
@media screen and (max-width: 320px) { 
    .new-header-grid {
        gap: 8px;
    }
    .new-auth-box .black-btn {
        max-width: 100px;
    }
  }