.footer {
  background: #143a6a;
  padding: 50px 0;
  direction: ltr;
}

.bottom-footer {
  position: relative;
}

.footer-logo img {
  max-width: 120px;
}

.bottom-footer p {
  margin: 0;
  color: #fff;
  font-size: 16px;
}

.top-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-menu ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-menu ul li a,
.footer-menu ul li span {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: right;
  letter-spacing: -0.01em;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
}

.footer-menu ul li span:hover,
.footer-menu ul li a:hover {
  color: #ebebeb !important;
}

.footer-social ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 10px;
  width: 100%;
}

/* .footer-social ul li {
  margin-left: 10px;
} */
.footer-social ul li:first-child {
  border-bottom: 3px solid #fff;
}
.footer-social ul li svg {
  width: 35px;
  height: auto;
  padding: 0 0 16px;
}

.footer-logo {
  margin-left: -16px;
  margin-top: 64px;
}
.footer-logo svg {
  width: 190px;
  height: 91px;
}
.bottom-footer {
  position: relative;
  padding-top: 65px;
  display: grid;
  justify-content: space-between;
  gap: 20px 35px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.footer-grid p {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.01em;
  margin: 0;
  color: #fff;
}

.footer-grid p img {
  margin-right: 10px;
}

.footer-grid ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.footer-grid ul li a {
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #ffffff;
  text-decoration: none;
  padding-bottom: 5px;
  display: block;
}

.footer-goto {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  transition: all 0.5s;
}

.footer-goto img {
  width: 45px;
}
.footer-goto:hover {
  opacity: 0.8;
}

.footer-search {
  cursor: pointer;
}

.footer-left-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-menu h2 {
  font-size: 49px;
  line-height: 38px;
  position: relative;
  color: #fff;
  font-family: "almoni-demibold", sans-serif !important;
  font-weight: 700;
  margin-bottom: 36px;
  direction: rtl;
}
.footer-menu h2:after {
  float: right;
  background: #fff;
  width: 43px;
  height: 2px;
  content: "";
  position: absolute;
  right: 0;
  bottom: -16px;
}
.footer-links-section {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}
.links-list a {
  text-decoration: underline !important;
}
p.address {
  color: #fff;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
  direction: rtl;
}
.powered-by {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.powered-by p {
  margin: 0;
  color: #fff;
  font-size: 27px;
  line-height: 34px;
  display: flex;
  gap: 10px;
  letter-spacing: 0.02em;
}
.powered-by svg {
  width: 83px;
  margin-top: -16px;
}
.footer-note {
  display: flex;
  justify-content: flex-end;
}
.note-section {
  border: 1px solid #fff;
  padding: 11px 30px 11px;
  font-weight: bold;
  max-width: 522px;
  width: 100%;
  text-align: center;
}
.note-section p {
  font-family: "almoni-demibold", sans-serif !important;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  vertical-align: top;
  border: none;
  letter-spacing: 0px;
}
a.footer-number {
  font-size: 24px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
  color: #ffffff;
  text-decoration: underline !important;
}
a.footer-number:hover {
  color: #ffffff !important;
}

/************************************************************************************************************/

/*************** Media Query ******************/

/************************************************************************************************************/

@media screen and (max-width: 992px) {
  p.address,
  .footer-menu ul li a,
  .footer-menu ul li span {
    font-size: 25px;
    line-height: 1;
  }
  .footer-menu ul {
    gap: 5px;
  }
}

@media screen and (max-width: 991px) {
  .footer-logo {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 40px 0px;
  }

  .top-footer {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 10px;
  }

  .footer-logo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 0;
  }
  .footer-logo svg {
    width: 150px;
    height: auto;
  }

  .footer-menu {
    width: 100%;
    padding-top: 20px;
  }

  .footer-search {
    width: 100%;
  }

  .footer-social {
    width: 100%;
    padding-top: 30px;
  }

  .footer-logo img {
    display: block;
    margin: 0px auto;
  }

  .footer-menu ul {
    flex-wrap: wrap;
  }

  .footer-menu ul li {
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .footer-menu ul li a,
  .footer-menu ul li span {
    padding-bottom: 10px;
    display: block;
  }

  .footer-search img {
    width: 16px;
    margin: 0px auto;
    display: block;
  }

  .footer-social ul {
    max-width: 200px;
    margin: 0px auto;
  }

  .footer-social ul li {
    margin: 0;
  }

  .footer-social ul li img {
    width: 40px;
  }

  .bottom-footer {
    gap: 26px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    max-width: 220px;
    margin: 0px auto;
    padding-top: 50px;
  }

  .footer-goto {
    position: absolute;
    bottom: -40px;
    right: -30px;
  }
  .bottom-footer {
    max-width: 100%;
    grid-template-columns: 30% 68%;
    padding-top: 20px;
    gap: 10px;
  }
  .footer-note {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    padding-top: 20px;
  }
  .footer-left-col {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    align-self: center;
  }
  .footer-logo svg {
    width: 100px;
    height: auto;
  }

  .footer-social {
    padding: 10px 0 0 8px;
    width: fit-content;
    margin: auto 0 0 0;
  }
  .footer-social ul {
    justify-content: center;
    max-width: 100%;
  }
  .footer-logo {
    margin: 0 auto 0 0;
    justify-content: center;
    width: fit-content;
  }

  .top-footer {
    grid-template-columns: 100%;
  }
  .footer-links-section {
    display: flex;
    grid-template-columns: 40% 26% 34%;
    justify-content: space-between;
    gap: 0px;
  }
  .footer-menu ul li a,
  .footer-menu ul li span,
  p.address {
    padding-bottom: 5px;
    font-size: 20px;
    line-height: 1;
  }
  p.address {
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
  }
  .footer-menu h2 {
    font-size: 30px;
    line-height: 1;
    margin-bottom: 20px;
  }
  .footer-menu h2:after {
    bottom: -6px;
  }
  .footer-menu {
    padding: 20px 0 0 0;
  }
  .footer-social ul li svg {
    width: 20px;
  }
  .bottom-footer {
    grid-template-columns: 100%;
  }
  .powered-by {
    max-width: fit-content;
    margin: auto;
    flex-direction: row;
    gap: 10px;
  }
  .powered-by p {
    font-size: 20px;
    line-height: 1;
  }
  .footer-note {
    padding: 0;
  }
}

@media screen and (max-width: 375px) {
  .footer-left-col {
    padding: 0;
    flex-direction: column-reverse;
    align-self: flex-start;
    justify-content: flex-start;
  }
  .footer-menu {
    padding: 0;
  }
  .footer-menu h2 {
    font-size: 28px;
  }
  .footer-menu ul li a,
  .footer-menu ul li span,
  p.address {
    font-size: 18px;
    margin-left: 20px;
  }
}
