.header {
  position: fixed;
  width: 100%;
  background: #fff;
  min-height: 165px;
  background: #18457d;
}

.top-left-logo-cont {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  background: url("../../assets/images/header/top_left_no_bg.png") 0 0 no-repeat;
  height: 178px;
  text-align: left;
  max-width: 800px;
  width: 100%;
  background-size: contain;
}

.top-left-logo-cont .bg-image {
  height: 191px;
  position: absolute;
  left: 0;
  top: 0;
}

.top-left-logo-cont .logo {
  opacity: 1;
  position: absolute;
  margin: 28px;
}

a {
  text-decoration: none !important;
}

.white-header.is-sticky .main-navigation {
  position: fixed;
  background: rgb(51 51 51 / 70%);
  top: 0;
  border-bottom: 1px solid rgb(51 51 51 / 70%);
}

.white-header svg path {
  fill: #fff;
}

.is-sticky .main-navigation {
  position: fixed;
  background: #fff;
  top: 0;
}

/****************** Top Header *****************/

.top-header {
  padding: 8px 0px;
  background: #f5f8f8;
  color: rgb(51 51 51 / 50%);
}

.white-header .top-header {
  padding: 8px 0px;
  background: #333333;
  color: #ced0d0;
}

.top-header-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-info {
  display: flex;
  align-items: center;
}

.contact-info span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin-right: 45px;
}

.white-header .contact-info span img {
  opacity: 1;
}

.contact-info span img {
  margin-right: 10px;
  opacity: 0.5;
}

.search-icon {
  margin-right: 40px;
  cursor: pointer;
}

.auth-login {
  display: flex;
  align-items: center;
}

.white-header .auth-login a {
  color: #ced0d0;
}

.auth-login a {
  color: rgb(51 51 51 / 50%);
  margin-left: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  text-decoration: none;
}

.white-header .auth-login a:hover {
  color: #ced0d0 !important;
}

.auth-login a:hover {
  color: rgb(51 51 51 / 50%) !important;
}

.auth-login a span {
  display: inline-block;
  margin-right: 20px;
}

.language-dropdown {
  margin-right: 20px;
}

.language-dropdown .btn,
.language-dropdown .btn:hover,
.language-dropdown .btn:focus,
.language-dropdown .show > .btn-success.dropdown-toggle:focus,
.language-dropdown .btn-success:active:focus {
  background: transparent;
  border-color: transparent;
  box-shadow: none;

  padding: 0px;
  position: relative;
}
.mainDiv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: rgb(24, 69, 125);
  padding: 0 10px;
  height: 88px;
  direction: rtl;
}
.mainDivHeb {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: rgb(24, 69, 125);
  padding: 0 10px;
  height: 88px;
  direction: ltr;
}

.headerArrow {
  position: fixed;
  right: 0;
  top: 0;
  /* z-index: 999; */
}
.headerArrowHeb {
  position: fixed;
  left: 0;
  top: 0;
}
.headerArrowHeb img {
  width: 800px;
  transform: scaleX(var(--scaleX, 1));
}

.headerArrow img {
  width: 800px;
  transform: scaleX(var(--scaleX, -1));
}

.mainMenu {
  background-color: rgb(24, 69, 125);
  position: fixed;
  left: 0;
  direction: rtl;
}
.mainMenuHeb {
  background-color: rgb(24, 69, 125);
  position: fixed;
  right: 0;
  direction: ltr;
}

.language-dropdown .dropdown-toggle::after {
  border: none;
  content: "";
  background: url(../../assets/images/header/arrow.svg);
  position: absolute;
  width: 9px;
  height: 5px;
  background-size: cover;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0px;
}

.language-mobile {
  display: none;
}
.language-mobile .contact-info span svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.logo img {
  max-width: 125px;
}
img.mob-logo {
  display: none;
}

/******************** Main navigation **************************/

.main-navigation {
  position: relative;
  width: 100%;
  top: 0px;
  padding: 10px 0px;
  /* border-bottom: 2px solid #F5F8F8; */
  background: #18457d;
}

.white-header .main-navigation {
  border-bottom: 1px solid rgb(245 248 248 / 20%);
}

.main-navigation-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.menu-grid {
  position: relative;
  transition: all 0.2s;
  opacity: 1;
  top: 28px;
  right: -110px;
  z-index: 15;
}

.menu-icon {
  display: none;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.menu-icon span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin-right: 26px;
}

.white-header .menu-icon span {
  color: #ffffff;
}

.menu-grid a,
.menu-grid .nav-item span {
  font-weight: 500;
  font-size: 32px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fff !important;
  display: inline-block;
  padding: 0px 8px;
  cursor: pointer;
}

.menu-grid .nav .nav-item:last-child a {
  font-family: "almoni-demibold";
  padding-right: 0;
}

.white-header .menu-grid a,
.white-header .menu-grid .nav-item span {
  color: #ffffff;
}

.menu-grid a:hover,
.menu-grid a:focus,
.menu-grid .nav-item span:hover,
.menu-grid .nav-item span:focus {
  /* color: rgb(145 145 145 / 90%)!important; */
  color: #fff;
}

.white-header .menu-grid a:hover,
.white-header .menu-grid a:focus,
.white-header .menu-grid .nav-item span:hover,
.white-header .menu-grid .nav-item span:focus {
  /* color: rgba(255, 255, 255, 0.60)!important; */
  color: #fff;
}

.open-menu .menu-grid {
  opacity: 1;
}

.open-menu .menu-icon span,
.open-menu .menu-icon img {
  display: none;
}

.white-header .main-navigation.open-menu {
  background: rgba(51, 51, 51, 0.7);
  border-bottom: 1px solid rgba(51, 51, 51, 0.7);
}

.open-menu .menu-icon:before {
  content: "";
  /* background: url(../../assets/images/header/close-dark.svg); */
  background-image: url("data:image/svg+xml, %3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M9.53208 7.88792L14.5439 2.88704C14.7634 2.66754 14.8867 2.36982 14.8867 2.05939C14.8867 1.74896 14.7634 1.45125 14.5439 1.23174C14.3244 1.01223 14.0268 0.888916 13.7164 0.888916C13.406 0.888916 13.1083 1.01223 12.8888 1.23174L7.88867 6.24428L2.8885 1.23174C2.66903 1.01223 2.37136 0.888916 2.06097 0.888916C1.75059 0.888916 1.45291 1.01223 1.23344 1.23174C1.01396 1.45125 0.890661 1.74896 0.890661 2.05939C0.890661 2.36982 1.01396 2.66754 1.23344 2.88704L6.24526 7.88792L1.23344 12.8888C1.12419 12.9972 1.03748 13.1261 0.97831 13.2681C0.919137 13.4102 0.888672 13.5626 0.888672 13.7165C0.888672 13.8703 0.919137 14.0227 0.97831 14.1648C1.03748 14.3068 1.12419 14.4357 1.23344 14.5441C1.34179 14.6534 1.4707 14.7401 1.61273 14.7993C1.75476 14.8584 1.90711 14.8889 2.06097 14.8889C2.21484 14.8889 2.36718 14.8584 2.50921 14.7993C2.65124 14.7401 2.78015 14.6534 2.8885 14.5441L7.88867 9.53157L12.8888 14.5441C12.9972 14.6534 13.1261 14.7401 13.2681 14.7993C13.4102 14.8584 13.5625 14.8889 13.7164 14.8889C13.8702 14.8889 14.0226 14.8584 14.1646 14.7993C14.3066 14.7401 14.4356 14.6534 14.5439 14.5441C14.6532 14.4357 14.7399 14.3068 14.799 14.1648C14.8582 14.0227 14.8887 13.8703 14.8887 13.7165C14.8887 13.5626 14.8582 13.4102 14.799 13.2681C14.7399 13.1261 14.6532 12.9972 14.5439 12.8888L9.53208 7.88792Z' fill='white' /%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 5px;
  top: -13px;
}

.white-header .open-menu .menu-icon:before {
  content: "";
  background: url(../../assets/images/header/close.svg);
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 5px;
}

.mobile-icons {
  display: none;
}

.header .bg-image {
  height: 191px;
  position: absolute;
  /* left: -12px;
    top: -12px; */
  left: 0;
  top: 0;
  z-index: 2;
}

/******************** Search Container **************************/

.searc-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
}

.searc-container.close-search {
  right: -100%;
  width: 0%;
}

.search-content {
  max-width: 437px;
  width: 100%;
}

.search-content img {
  margin: 0px auto;
  display: block;
  margin-bottom: 38px;
}

.search-content form input {
  background: #f5f8f8;
  border-radius: 122px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.01em;

  color: #ced0d0;
  height: 54px;
  padding: 10px 24px;
}

.search-content form input:focus {
  background: #f5f8f8;
  box-shadow: none;
  border-color: #f5f8f8;
}

.search-content form input::placeholder {
  color: #ced0d0;
}

.search-content form {
  position: relative;
}

.search-content form .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  background: #18457d;
  border-radius: 100%;
  border-color: #18457d;
  line-height: 45px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
}

.search-content form .btn:focus {
  box-shadow: none;
}

.search-content form .btn img {
  margin: 0px auto;
}

.search-close-btn {
  background: transparent !important;
  width: 45px;
  height: 45px;
  opacity: 1;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  position: absolute;
  right: 20%;
  top: 20%;
  cursor: pointer;
}

.search-close-btn img {
  margin: 0px auto;
}
.img_globe {
  padding: 2px;
  background: white;
  border-radius: 999px;
  margin-right: -7px;
}

.globe_image {
  height: 23px;
  width: 23px;
}

.custom-select {
  background-color: rgb(24, 69, 125);
  color: white;
  border: none;
  margin-top: -9px;
  width: 18px;
  margin-left: -6px;
}
.logo {
  height: 60px;
}
.Blue_logo {
  display: none;
}
.nav-link {
  color: white;
  font-size: 22px;
}
.userName {
  font-size: 20px;
  color: white;
  padding: 4px 10px;
}
.btn-primary {
  --bs-btn-bg: rgb(24, 69, 125);
  --bs-btn-border-color: rgb(24, 69, 125);
}
.btn-secondary {
  --bs-btn-bg: rgb(24, 69, 125);
  --bs-btn-border-color: rgb(24, 69, 125);
}
.dropstart .dropdown-toggle {
  transform: rotate(270deg);
}
.btn:first-child:hover,
:not(.btn-check) + .btn:hover {
  background-color: rgb(24, 69, 125);
  border-color: rgb(24, 69, 125);
}

.buttonDrop button {
  background: #18457d;
  background-size: cover;
  border-radius: 100px;
  width: 50px;
  height: 32px;
  font-size: 22px;
}
.buttonDrop button::after {
  display: none;
}
.buttonDrop .dropdown-menu {
  /* left:inherit;
    right: 0; */
  background-color: rgb(24, 69, 125);
  --bs-dropdown-link-hover-bg: rgb(24, 69, 125);
  text-align: justify;
  --bs-dropdown-min-width: 6rem !important;
}
.buttonDrop .dropdown-menu .active {
  color: #fff;
  background-color: rgb(24, 69, 125);
}
.buttonDrop .dropdown-menu:hover {
  background-color: rgb(24, 69, 125);
}
.listDrop .dropdown-menu {
  left: inherit;
  right: 0;
}
.listDrop button:hover {
  background-color: transparent !important;
  color: #fff !important;
}
.nav_div a:focus {
  color: white;
  background-color: #18457d;
}
.nav_div a {
  color: white;
  padding: 10px;
  font-size: 22px;
  font-family: almoni-light, sans-serif !important;
}
.nav_div a:hover {
  color: white !important;
}
.linkdropdown {
  display: none;
}
a.logo-link:focus {
  background-color: transparent;
}

/************************************************************************************************************/

/*************** Media Query ******************/

/************************************************************************************************************/

@media (max-width: 767px) {
  .buttonDrop .dropdown-menu {
    text-align: justify;
  }
  .headerArrow {
    display: none;
  }
  .headerArrowHeb {
    display: none;
  }
  .Blue_logo {
    display: block;
    height: 45px;
  }
}

@media screen and (max-width: 1436px) {
  .header .bg-image {
    /* height: auto; */
    left: -180px;
    top: -10px;
    width: 100%;
  }
  .logo img {
    max-width: 112px;
  }

  .menu-grid {
    right: 0px;
  }
}

@media screen and (max-width: 992px) {
  .linkdropdown {
    display: block;
  }
  .languagedropdown {
    display: none;
  }
  .header {
    min-height: auto;
    min-height: 165px;
  }
  .logo img {
    max-width: 110px;
  }
  .header .bg-image {
    left: 0;
    top: 5px;
    margin: -10px -12px;
  }
  .menu-grid {
    right: 0px;
  }
  .menu-icon span {
    display: none;
  }

  .mobile-icons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 50px;
    z-index: 9;
  }

  .mobile-icons span {
    display: inline-block;
    margin: 0px 15px;
    cursor: pointer;
  }

  .search-content {
    padding: 0px 15px;
  }

  .search-close-btn {
    right: 12px;
    top: 36px;
  }

  .search-content img {
    margin-bottom: 30px;
    width: 120px;
  }

  .search-content form .btn img {
    width: auto;
  }

  .search-close-btn img {
    width: auto;
    margin: 0px auto;
  }

  .menu-icon {
    z-index: 99;
    display: flex;
    position: absolute;
    right: 2px;
  }

  .open-menu .menu-icon {
    z-index: 99;
    background: transparent !important;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    position: fixed;
    right: 12px;
    top: 36px;
  }

  .main-navigation-grid {
    height: 68px;
  }

  .menu-grid {
    transition: all ease 0.5s;
    right: -100%;
  }

  .open-menu .menu-grid {
    position: fixed;
    top: 0;
    background: rgb(0 0 0 / 90%);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
  }

  .main-navigation.open-menu {
    background: transparent;
    border-bottom: 1px solid transparent;
  }

  .main-navigation .nav {
    margin-top: 50px;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .menu-grid a,
  .menu-grid .nav-item span {
    font-weight: 700;
    padding: 21px 20px;
    color: #fff;
  }

  .language-mobile {
    display: block;
    padding-top: 30px;
  }

  .language-mobile .contact-info span {
    display: block;
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #ffffff;
    padding-bottom: 10px;
  }

  .language-mobile .language-dropdown .dropdown {
    text-align: center;
  }

  .language-mobile .language-dropdown {
    margin: 0;
    padding-top: 40px;
  }

  .language-mobile .show > .btn-success.dropdown-toggle {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  .language-mobile .dropdown-menu {
    background: transparent !important;
    padding: 0;
  }

  .language-mobile .dropdown-menu .dropdown-item {
    text-align: left;
    display: block;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: #ffffff;
    padding: 10px 0px;
    background: transparent !important;
  }
}
@media screen and (max-width: 991px) {
  .top-left-logo-cont {
    max-width: 75%;
  }
}

@media screen and (max-width: 767px) {
  html {
    overflow-x: hidden;
  }

  .header .bg-image {
    /* margin: -19px -12px; */
    display: none;
  }

  .logo img {
    display: none;
  }
  .img .mob-logo {
    display: block;
  }

  .top-header {
    display: none;
  }

  .main-navigation {
    top: 0;
    padding: 19px 0px 19px;
  }

  /* .menu-grid {
        right: 0px;
    } */

  .logo img {
    width: 100%;
  }

  .logo {
    width: 100px;
  }

  .main-navigation-grid {
    height: 38px;
  }

  .open-menu .menu-icon:before {
    content: "";
    background: url(../../assets/images/header/close.svg);
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    right: 5px;
  }

  .open-menu .menu-icon:before {
    right: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .language-mobile .contact-info span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #ffffff;
    padding-bottom: 10px;
  }
  .language-mobile .dropdown-menu .dropdown-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #ffffff;
    padding: 10px 0px;
  }

  .contact-info span img {
    opacity: 1;
  }
  .top-left-logo-cont {
    height: 30px;
  }
  .header {
    min-height: 102px;
  }
}

@media screen and (max-width: 480px) {
  .main-navigation {
    padding: 20px 0;
    background: #18457d;
  }
  .main-navigation-grid {
    height: 32px;
  }
  .logo {
    width: 100px;
    max-width: 70px;
    /* display: none;
    background-image: url("../../assets/images/header/logo_white.png"); */
  }

  .header .bg-image {
    top: 11px;
    width: 140%;
    left: -56px;
  }
  .mobile-icons span {
    margin: 0px 10px;
  }
  .mobile-icons {
    right: 30px;
  }
  .menu-icon svg,
  .mobile-icons svg {
    width: 15px;
  }
}
@media screen and (max-width: 375px) {
  .Blue_logo {
    display: block;
    height: 45px;
  }
  .header .bg-image {
    display: none;
  }
}
