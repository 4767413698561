.listDiv {
  display: flex;
  width: 100%;
  padding: 10px;
  text-align: center;
  margin-top: 15%;
}
.queuesContainer {
  height: 100vh;
}
.comsign_form {
  width: 60%;
  margin: 0 auto;
}
.ql-editor ol,
.ql-editor ul {
  padding: 0 1.5em !important;
}
.ql-editor ol li:not(.ql-direction-rtl) {
  padding: 0 1.5em !important;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  /* margin-left: -1.5em; */
  margin: 0.3em !important;
  text-align: right;
}

.ListData {
  width: 30%;
  margin: 10px;
}
.ql-container p {
  font-size: 24px;
}

.box {
  padding: 20px;
  text-align: center;
  font-size: 25px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.SummaryTopButtons {
  display: flex;
  justify-content: space-around;
  /* background: linear-gradient(90deg, #fff, #eee 90%); */
  /* padding: 0 0 0 21%; */
}
.summary_pres {
  /* font-size: 38px;
  padding: 10px 10px;
  color: #14427d;
  cursor: pointer; */
  /* background: #eee; */
  /* -webkit-clip-path: polygon(
    5% 0,
    100% 0,
    100% 100%,
    5% 100%,
    0 50%
  ) !important; */
  /* clip-path: polygon(5% 0, 100% 0, 100% 100%, 5% 100%, 0 50%) !important; */
  color: #18457d;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  list-style-type: none;
  padding: 13px 10px;
  text-align: center;
  width: 40%;
}
.summary_press_active {
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  list-style-type: none;
  padding: 13px 10px;
  text-align: center;
  width: 25%;
  /* background: #18457d; */
  /* clip-path: polygon(5% 0, 100% 0, 100% 100%, 5% 100%, 0 50%) !important; */
  color: #18457d;
  border-bottom: 3px solid;
}
.form_sammary_press {
  min-height: 80%;
}
.save_sammary_press {
  margin: 10px;
  background: #fff;
}
.two_button {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.css-qbdosj-Input:focus {
  display: none !important;
}
.spinner svg {
  position: absolute !important;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.sign_sammary_press {
  font-size: 16px;
  /* font-weight: bold; */
  /* color: #14427d; */
  /* border: 2px solid #14427d; */
  padding: 10px 20px;
  /* border-radius: 50px; */
  cursor: pointer;
  text-decoration: underline;
  font-family: "Heebo" !important;
  margin: 10px;
}
.ql-toolbar.ql-snow {
  border: 0px !important;
  border-bottom: 1px solid #14427d !important;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}
.ql-container.ql-snow {
  border: 0px !important ;
}
.quill {
  border: 3px solid #14427d;
  border-radius: 24px;
}

.headerText {
  font-size: 44px;
  font-weight: bold;
  color: #1E0F3C;
  font-family: var(--assistant) !important;
}
.almost {
  font-size: 44px;
  font-weight: bold;
  color: #1E0F3C;
  font-family: var(--assistant) !important;
}

.desk {
  font-size: 25px;
  font-weight: bold;
  color: #14427d;
}

.box :hover {
  cursor: pointer;
}

h1 {
  text-align: center;
  font-weight: bold;
  color: var(--text-primary);
  font-size: 35px;
  line-height: 34px;
  font-family: var(--assistant) !important;
}

.box {
  display: inline-block;
  /* padding: 10px; */
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.buttonStyle {
  margin-top: 15%;
  display: flex;
  justify-content: space-around;
}

.firstDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.cropsection {
  display: flex;
  justify-content: center;
}

.timesection {
  flex-wrap: nowrap;
  margin-bottom: 10px;
  justify-content: center;
}

.react-date-picker {
  width: 50%;
}

.react-date-picker__inputGroup {
  min-width: 63%;
}

.allmeeting {
  display: flex;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 10px 20px;
  width: 62%;
  margin: 0 auto;
  flex-direction: column;
}

.react-date-picker__inputGroup {
  font-size: 24px;
  font-weight: bold;
}

.jodit-container {
  width: 90%;
}

.abc span {
  font-size: 25px;
  color: var(--text-primary);
}

.abc input {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.frequency {
  width: 50%;
  margin: 5px;
  position: relative;
}

.menu_button {
  border: none;
  background-color: white;
  padding: 0 15px;
  display: flex;
}

.button:hover {
  background-color: white !important;
  border-color: #14427d !important;
  color: #14427d !important;
  font-weight: 700;
}

.action :hover {
  background-color: white !important;
  color: #14427d !important;
  font-weight: 700;
}

.documentList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  color: #14427d;
}

.documentBody {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: auto;
}
.meetingUpdate_card {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: auto;
  padding: 10px 15%;
}

.documentBody::-webkit-scrollbar {
  display: none;
}

.summaryModal {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  background-color: rgb(255, 255, 255);
  width: 90% !important;
  margin: 6% auto;
  height: 80%;
  border: 4px solid #14427d;
  border-radius: 16px !important;
}
.admin-meeting-update .meetingUpdate_card {
  overflow-x: hidden;
}
.summaryModal.doctor-summary {
  width: 98%!important;
  height: calc(100vh - 8%);
  margin: 2% auto;
}
.summaryModal.doctor-summary  .card-body {
  padding-top: 0;
}
.summaryModal.doctor-summary.doc-list-modal  .card-body {
  padding-bottom: 0;
}
.summaryModal.doctor-summary .toggleDiv {
  position: sticky;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  background: #ffffff;
  padding: 16px 0px;
  margin: 0;
  z-index: 9;
  display: block;
}
.tab-grid {
  display: flex;
  justify-content: space-between;
}
.user-name {
  padding-top: 10px;
}
.user-name h5 {
  margin: 0;
  color: var(--text-primary);
  font-family: var(--assistant) !important;
  font-weight: 700;
}
.summaryModal.doctor-summary form.summarytext ,.summaryModal.doctor-summary .form_sammary_press{
  padding-top: 0px;
}
.summaryModal.doctor-summary .form_sammary_press {
  min-height: 80%;
}
.form_sammary_field {
  margin-bottom: 30px;
}
.form_sammary_field h2 {
  color: var(--text-primary);
  font-family: var(--assistant) !important;
  font-size: 26px;
}
.form_sammary_field .form-label {
    color: #685E7C;
    font-size: 18px;
    line-height: 27px;
    position: relative;
    width: 100%;
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-family: var(--assistant) !important;
}

.modal-close-btn {
  width: 20%;
}
.modal-close-btn button {
  width: 100%;
  border-radius: 28px;
  border-color: #000;
  font-size: 20px;
}
.summaryModal.doctor-summary .two_button {
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
.two_button-left {
  display: flex;
  align-items: center;
  width: 50%;
}
.doc-with-sumary .two_button-left {
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.two_button-right {
  width: 50%;
}
.doc-with-sumary .two_button-right {
  width: fit-content;
}
.doc-with-sumary .save_sammary_press {
  margin: 0;
}
.doc-with-sumary .sign_sammary_press {
  margin: 0;
}
.doc-with-sumary .two_button {
  margin-top: 15px;
  padding: 0px 2%;
}
.note-text span {
  font-size: 18px;
  color: #685E7C;
  font-family: var(--assistant);
}
.red-border {
  border: 1px solid red;
  border-radius: .375rem;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 17px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
} */

.updatesent {
  width: 39% !important;
}

.addmorebtn {
  margin-top: 5px !important;
}

.paginate {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  color: #ffff;
}

.paginate li {
  display: flex;
  margin: 2px 5px;
}

.paginate a {
  color: #fff;
}

.paginationDiv {
  width: 100%;
  background-color: #14427d;
}

.datecustom {
  width: 100% !important;
  border: 1px solid rgb(231, 229, 229) !important;
  padding: 4px 10px !important;
  border-radius: 1px !important;
}
.ql-editor {
  text-align: inherit !important;
  -webkit-user-select: text;
}
.crop_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 25px;
  border: 4px solid rgb(24, 69, 125);
}

@media (max-width: 1024px) {
  .summaryModal {
    width: 80% !important;
  }
}

@media (max-width: 991px) {
  .textchange h3 {
    font-size: 16px;
  }

  .summaryModal {
    width: 80% !important;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
  .summaryModal {
    width: 80% !important;
  }

  .textchange h3 {
    font-size: 16px;
  }

  .headerText {
    font-size: 22px;
  }
  .queuesLabel {
    display: none;
  }
  .summaryModal.doctor-summary .two_button .two_button-left {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .summaryModal.doctor-summary .two_button .two_button-right {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .note-text {
    text-align: left;
  }
  .modal-close-btn {
    width: 50%;
    margin: 0px 5px;
    margin-top: 12px;
  }
  .modal-close-btn button {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .summaryModal.doctor-summary .form_sammary_press {
    min-height: 71%;
  }
  .medicine-row {
    flex-wrap: wrap;
  }
  .medicine-row .frequency {
    width: 100%;
  }
  .summaryModal.doctor-summary.doc-list-modal  .card-body {
    padding: 0 5px;
  }
  .almost {
    font-size: 36px;
  }
  .form_sammary_field h2 {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .queuesLabel {
    display: none;
  }
  .headerText {
    font-size: 17px;
  }
  .summary_pres {
    padding: 0 10px;
    height: 40px;
    width: 40%;
  }
  .summary_press_active {
    padding: 0 10px;
    height: 40px;
    width: 40%;
  }
  .sign_sammary_press {
    font-size: 16px;
    border-radius: 28px;
    padding: 0 10px;
    text-align: center;
    margin: 0;
  }
  .save_sammary_press {
    margin: 0 5px;
  }
}

@media (max-width: 360px) {
  h1 {
    display: none;
  }
  .headerText {
    font-size: 15px;
  }
  .summary_pres {
    padding: 0 10px;
    height: 40px;
    width: 40%;
  }
  .summary_press_active {
    padding: 0 10px;
    height: 40px;
    width: 40%;
  }
}
