* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.statusWrapper {
  /* background: var(--foundation-btn-color); */
  padding-top: 80px;
  padding-bottom: 24px;
}
.statusWrapper .container, .userWrapper .container {
  max-width: 900px!important;
  width: 100%;
  padding: 0px 24px !important;
}
.only-rx-doc .statusWrapper.doctor-dashboard {
  padding-top: 24px;
}
 .only-rx-doc .userWrapper .container {
  max-width: 1180px !important;
}
.only-rx-doc .ag-root-wrapper {
  border: none;
  border-radius: 12px;
}
.rx-doctor .ag-root-wrapper {
  border-radius: 8px;
}
.table-title h2 {
  margin: 0;
  padding-bottom: 24px;
}

.loader {
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.grecaptcha-badge {
  bottom: 36px !important;
  visibility: hidden !important;
}

.pay_loader {
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payloadercontent {
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.loadertext {
  font-weight: 600;
  font-size: 22px;
  padding-bottom: 10px;
}

.notificationcheck {
  width: 60px;
  height: 17px;
}

.toggletab {
  display: none;
}

.toggleContent {
  display: block;
}

.toggleDiv {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.toggle {
  line-height: 34px;
  font-size: 22px;
  color: var(--text-primary);
  font-family: var(--assistant) !important;
  background: var(--foundation-btn-color);
  width: 50%;
  text-align: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 8px;
  cursor: pointer;
}

.summary_text {
  border: 3px solid #000;
  border-radius: 24px;
  font-size: 22px;
  color: var(--text-black);
  font-family: var(--assistant);
}

.summary_text:focus {
  color: var(--text-black);
  border: 3px solid #000;
}
.error-color {
  border-color: red;
}

.adminDataTable {
  padding-top: 40px;
  background: #f1f1f1;
  padding-bottom: 5%;
}

.download_report {
  margin: 10px;
}

.collecttive_Container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.collecttive_Container .datePicker {
  width: 90% !important;
}

.idf-report .collecttive_Container {
  align-items: flex-end;
}

.idf-report .collecttive_Container .download_report {
  margin-bottom: 0;
}

.collective_content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
}

.searchBox {
  height: 0px !important;
  width: auto !important;
}

.spanActive {
  line-height: 34px;
  font-family: var(--assistant);
  font-size: 22px;
  color: var(--foundation-btn-color);
  background: var(--foundation-violet);
  width: 50%;
  text-align: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 8px;
  cursor: pointer;
}

.statusWrapper .innerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statusWrapper .innerWrapper .itemBoxHeb {
  background: var(--foundation-btn-color);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e3e3e3', endColorstr='#e6e6e6', GradientType=0);
  /* IE6-9 */
  border-radius: 35px;
  padding: 40px 10px;
  width: 32%;
  position: relative;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.doctor-dashboard.statusWrapper .innerWrapper {
  gap: 20px;
}


.statusWrapper .innerWrapper .itemBoxEng {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid transparent;
  background: rgb(227, 227, 227);
  /* Old browsers */
  /* background: -moz-linear-gradient(top,  rgba(227,227,227,1) 0%, rgba(230,230,230,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(227,227,227,1) 0%,rgba(230,230,230,1) 100%);  */
  /* background: linear-gradient(to bottom,  rgba(227,227,227,1) 0%,rgba(230,230,230,1) 100%);  */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e3e3e3', endColorstr='#e6e6e6', GradientType=0);
  /* IE6-9 */
  border-radius: 35px;
  padding: 40px 10px;
  width: 32%;
  position: relative;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.statusWrapper .innerWrapper .activeDashTab {
  background: #fff;
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.16);
}
.statusWrapper.idf-mode .innerWrapper .itemBoxHeb, .statusWrapper.idf-mode .innerWrapper .itemBoxEng {
  width: 49%;
}

.commentColumn {
  cursor: pointer;
}

.statusWrapper .innerWrapper .itemBoxHeb img {
  position: absolute;
  top: 15px;
  right: 15px;
}

.statusWrapper .innerWrapper .itemBoxHeb:hover {
  background: #fff;
  border: none;
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.16);
  transition: 0.5s;
}

.statusWrapper .innerWrapper .itemBoxHeb h2 {
  font-weight: normal;
  margin: 0;
  padding: 0;
  line-height: 34px;
  text-align: center;
  color: var(--text-primary);
  font-family: var(--assistant) !important;
}

.statusWrapper .innerWrapper .itemBoxHeb h2 strong {
  display: block;
  line-height: 34px;
  font-family: var(--assistant) !important;
}

.statusWrapper .innerWrapper .itemBoxEng:hover {
  background: #fff;
  border-top: 2px solid transparent;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.statusWrapper .innerWrapper .itemBoxEng h2 {
  font-weight: normal;
  margin: 0;
  padding: 0;
  line-height: 34px;
  text-align: center;
}

.statusWrapper .innerWrapper .itemBoxEng h2 strong {
  display: block;
  line-height: 34px;
  font-family: "almoni-demibold";
}

.userWrapper {
  position: relative;
  z-index: 1;
}

.userInnerWrapper {
  background: #fff;
  position: relative;
  border-radius: 25px;
  padding: 55px;
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.16);
}

.fade_content {
  opacity: 0.3;
}

/* .userInnerWrapper::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 105%;
  background: url(../../assets/images/dashboard/shadow.png) no-repeat;
  background-position: top;
  background-size: 100%;
  height: 100%;
  z-index: -1;
} */

.offeredDateText {
  color: #14427d;
}

.phone_number {
  direction: ltr !important;
}

input[type="number"]:focus {
  border: 2px solid #14427d;
}

.react-datepicker {
  direction: ltr !important;
}

.userInnerWrapper ul {
  margin-bottom: 0;
  min-height: 240px;
}

.datetimeshift {
  color: #1E0F3C;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.userInnerWrapper ul li {
  list-style: none;
  border-bottom: 1px solid rgb(0 0 0 / 50%);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.userInnerWrapper ul li .userInfoBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.userInnerWrapper ul li .userInfoBox .docPrfo {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.gender_content {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  max-width: 72px;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .imgWrap {
  width: 30%;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .imgBox {
  width: 145px;
  height: 145px;
  border: 2px solid #18457d;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 30px;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .imgBox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .contentBox {
  width: 55%;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .contentBox .innerBx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .contentBox h3 {
  font-size: 16px;
  text-transform: capitalize;
  color: #18457d;
  margin-bottom: 5px;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .contentBox h2 {
  font-size: 24px;
  text-transform: uppercase;
  color: #18457d;
  font-weight: bold;
  margin-bottom: 5px;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .contentBox h4 {
  font-size: 18px;
  text-transform: capitalize;
  color: #18457d;
  margin-bottom: 0;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drInfo .contentBox .location {
  font-size: 14px;
  color: #18457d;
  margin-top: 15px;
}

.userInnerWrapper ul li .userInfoBox .drDate {
  border-left: 2px solid #cccccc;
  padding-left: 30px;
  margin-left: 30px;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drDate .date {
  font-size: 45px;
  line-height: 45px;
  color: #18457d;
  font-weight: bold;
  margin-bottom: 5px;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drDate .dayTime {
  font-size: 18px;
  color: #18457d;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userInnerWrapper ul li .userInfoBox .docPrfo .drDate .dayTime span {
  font-weight: normal;
}

.userInnerWrapper ul li .userInfoBox .docEdu {
  background: #f1f1f1;
  border-radius: 20px;
  padding: 25px 10px 10px 25px;
  width: 35%;
}

.userInnerWrapper ul li .userInfoBox .docEdu .medinfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.userInnerWrapper ul li .userInfoBox .docEdu .medinfo h3 {
  color: #909090;
  font-size: 18px;
  margin-bottom: 0;
  margin-right: 5px;
}

.userInnerWrapper ul li .userInfoBox .docEdu .medinfo h3.active {
  color: #18457d;
}

.userInnerWrapper ul li .userInfoBox .docEdu .medinfo button {
  border: none;
  outline: none;
  background: #fff;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 14px;
  color: #999999;
  transition: 0.5s;
  width: 70px;
}

.userInnerWrapper ul li .userInfoBox .docEdu .medinfo button.active,
.userInnerWrapper ul li .userInfoBox .docEdu .medinfo button:hover {
  background: #18457d;
  color: #fff;
  transition: 0.5s;
}

.userDiv {
  align-items: center !important;
}

.imgDiv {
  width: 15% !important;
}

.details {
  width: 85%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.hide {
  display: none;
}

.myDIV:hover+.hide {
  display: block;
  color: #14427d;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.nameDate {
  display: flex;
  align-items: center !important;
}

.prof {
  text-align: left !important;
}

.survey {
  cursor: auto;
}

.prof h3 {
  font-size: 16px;
  text-transform: capitalize;
  color: #18457d;
  margin-bottom: 5px;
}

.uploadclose {
  display: flex;
  justify-content: space-evenly;
}

.prof h2 {
  font-size: 24px;
  text-transform: uppercase;
  color: #18457d;
  font-weight: bold;
  margin-bottom: 5px;
}

.prof h4 {
  font-size: 20px;
  text-transform: capitalize;
  color: #18457d;
  margin-bottom: 0;
}

.date {
  font-size: 45px;
  text-transform: uppercase;
  color: #18457d;
  font-weight: bold;
  margin-bottom: 5px;
}

.dayTime span {
  font-size: 20px;
  text-transform: uppercase;
  color: #18457d;
  font-weight: normal;
  margin-bottom: 5px;
}

.medinfo h3 {
  font-size: 22px;
  color: #18457d;
  font-weight: normal;
  margin-bottom: 5px;
}

.update_smg {
  text-align: center !important;
  font-weight: bold !important;
  font-size: 22px !important;
}

.sizeCorr h2 {
  font-size: 24px;
  color: #18457d;
  margin-bottom: 5px;
}

.sizeCorr h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

.dayhours {
  font-size: 28px;
  color: var(--text-primary);
  padding: 0 5px 0 0;
  font-weight: 600;
}

.asap {
  font-size: 1.5rem;
  margin: 3px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ag-body-viewport {
  overflow: scroll !important;
}

.ag-body-viewport::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
}

.ag-body-viewport::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.updatemodal::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
}

.updatemodal::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.Iframe_content {
  text-align: center !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 35px;
}

.tranzila_iframe {
  width: 100%;
  height: 100%;
  border-radius: 35px;
}

.support_text {
  font-weight: bold;
  font-family: var(--assistant) !important;
  color: #685E7C;
}
.disclaimer-text {
  font-family: var(--roboto) !important;
  color: #685E7C;
  font-size: 16px;
}

.iframeclose {
  margin: 10px auto;
  width: 106px;
}
.iframeclose.payment {
  margin: 20px auto;
  background: var(--foundation-violet);
  color: var(--foundation-btn-color);
}
.iframeclose.payment:hover {
  background: var(--foundation-violet) !important;
  color: var(--foundation-btn-color) !important;
  opacity: 0.8 !important;
}

.showday {
  font-size: 28px;
  color: var(--text-primary);
  font-weight: 700;
  padding: 5px;
}

.showdayHeb {
  font-size: 28px;
  color: var(--text-primary);
  font-weight: 600;
  padding: 5px;
  padding-right: unset;
}

.showdayEng {
  font-size: 22px;
  color: var(--text-primary);
  font-weight: 600;
  padding: 5px;
  padding-left: unset;
}

.scheduleDate1 {
  display: flex;
  align-items: center;
}

.inputdate {
  display: flex;
  align-items: center;
}

.radioSize {
  height: 20px;
  width: 20px;
  padding: 5px;
}

.middleDiv {
  width: 10%;
}

.imgSize {
  position: absolute;
  top: 16px !important;
  left: 12px !important;
  width: 80% !important;
  height: 80% !important;
  object-fit: cover;
}

.check {
  display: flex;
  align-items: center;
}

.check img {
  width: 12px;
  height: 10px;
  margin-right: 3px;
}

.not_ready {
  background: gray !important;
  width: 65px !important;
}

.no_document {
  padding: 0 14px;
}

.dateDiv {
  width: 63% !important;
}

.timeDiv {
  border-radius: 29px;
  border: 1px solid #18457d;
}

.cropper-crop {
  background-color: #fff !important;
}

.dateSelect {
  display: flex;
  justify-content: space-around;
  margin: 25px 2px;
}

.inputDiv {
  display: block;
  /* width: 100%; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.time-grid select:disabled {
  background-color: #e9ecef;
}

select.select-time {
  text-align: center;
}

.closeIframe {
  height: 50px;
  width: 50px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.documentClose {
  margin-top: 20px;
}

.nav-tabs .nav-link {
  color: #14427d;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #f1f1f1;
}

.nav-tabs .nav-link.active {
  color: #fff !important;
  font-weight: bold !important;
  text-align: center;
  width: 100%;
  background: #14427d;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.nav-item {
  width: 50%;
}

.textDiv {
  font-size: 30px;
  border-radius: 10px;
  border: 3px solid #000 !important;
  padding: 0 6px;
  margin-bottom: 17px;
  color: #000;
}

.textchange h2 {
  font-size: 18px;
  text-align: start;
  padding: 2px 0;
  font-family: var(--assistant) !important;
  color: #000;
}

.summarytext h3 {
  font-size: 24px;
  font-weight: 500;
  padding: 10px 0;
  color: var(--foundation-violet);
  font-family: var(--assistant) !important;
}

.commenttext {
  text-align: start;
  padding: 0 !important;
}

.textchange h3 {
  font-size: 22px;
  font-weight: 500;
  padding: 2px 34px;
  color: #685E7C;
  font-family: var(--assistant) !important;
}

.no_meeting {
  text-align: center;
  font-weight: bold;
  color: var(--text-primary);
}

.payButton {
  border-radius: 20px;
  padding: 25px 10px 10px 25px;
  width: 18%;
}

/* new css for client future */
.userInnerWrapB ul {
  padding-left: 0;
}

.cfBase {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.cfBase1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mri_ct_xray {
  margin: 10px;
}

.cfFirst1 {
  display: flex;
  width: 19%;
  justify-content: space-between;
}

.sortshiftwidth {
  width: 40% !important;
}

.cfFirst {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.cfImgbg {
  display: flex;
  align-items: center;
}

.listdocument {
  padding: 0 !important;
}
.doc-with-sumary .listdocument {
  padding: 0 !important;
  min-height: 30%;
  overflow: auto;
  max-height: 30%;
}
.inner-summary {
  height: 100%;
  overflow: auto;
}
.doc-with-sumary .summarytext, .doc-with-sumary .form_sammary_press {
  padding: 0px 2%;
}
.doc-with-sumary .user-name {
  padding-left: 2%;
  padding-right: 2%;
}
.doc-with-mid h1 {
  font-size: 2rem;
  line-height: 120%;
}
.doc-with-mid .doc-grid {
  height: 56%;
  gap: 5px;
}
.navigation-btn {
  width: 24px;
  height: 24px;
  padding: 0;
}

.cfImgmain {
  padding-right: 30px;
  padding-left: 30px;
}

.cfImgmain img {
  width: 135px;
  height: 135px;
  border: 2px solid #000;
  border-radius: 100px;
  object-fit: contain;
}

.cfImgTxt h3 {
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: #685E7C;
  font-weight: bold;
}

.cfImgTxt h2 {
  color: #1E0F3C;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  font-family: var(--assistant) !important;
}

.cfImgTxt h4 {
  font-size: 18px;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 0;
  font-family: var(--assistant) !important;
}
.cfImgTxt h5 {
  font-family: var(--assistant) !important;
  color: #1E0F3C;
}

.cfLocate {
  font-size: 20px;
  color: #18457d;
  margin-top: 15px;
  line-break: anywhere;
}

.cfDate {
  /* border-left: 1px solid #ccc; */
  margin-left: 30px;
  margin-top: 0;
  padding: 0 30px;
  height: 68px;

  text-align: center;
}

.rightborder {
  border-right: 2px solid #cccccc;
  width: 54%;
}

.leftborder {
  border-left: 2px solid #000;
  width: 54%;
}

.rightpadding {
  padding: 0 7px 0 0;
}

.leftpadding {
  padding: 0 0 0 7px;
}

.datebox {
  display: none;
}

.cfDate h3 {
  font-size: 22px;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: #685E7C;
  font-weight: bold;
  font-family: var(--assistant);
}

.cfDate h2 {
  color: #1E0F3C;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.cfDate span {
  font-size: 20px;
  color: var(--text-primary);
  font-weight: 300;
  font-family: var(--assistant);
}

.cfDay {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cflast {
  background: var(--foundation-btn-color);
  border-radius: 20px;
  padding: 25px 10px 10px 25px;
  width: 35%;
}

.cfInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.btn:disabled {
  background-color: #c4c9cf;
  border-color: #c4c9cf;
  color: var(--text-primary);
}

.cfInfo1 {
  text-align: center;
}

.requestdate {
  border-radius: 20px;
  padding: 25px 10px 25px 25px;
  display: flex;
  justify-content: space-between;
}

.offerMeeting {
  width: 30% !important;
}

.dateforpay {
  width: 40% !important;
}

.offer {
  margin: auto 0;
}

.buttton_content {
  font-size: 16px;
  font-family: var(--assistant) !important;
  font-weight: bold;
  background: var(--foundation-violet);
  color: var(--foundation-btn-color);
  border-radius: 28px;
}
.buttton_content:hover {
  background: var(--foundation-violet) !important;
}
.close-doc-btn {
  margin-top: 15px;
}

.removebuttton_content {
  font-size: 16px;
  font-family: var(--assistant) !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 28px;
}
.removebuttton_content:hover {
  opacity: 0.8;
  background: #dc3545 !important;
  border-color: #dc3545 !important;
}

.cfInfo1 h3 {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 5px;
  color: #18457d;
}

.cfInfo1 h2 {
  font-size: 25px;
  color: #14427d;
  line-break: anywhere;
}

.cfChk {
  display: flex;
  align-items: center;
}

.cfChk h3 {
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 5px;
  font-family: var(--roboto) !important;
}

.cfChk h3.active {
  color: var(--text-primary);
}

.cfChk h3.phone-num {
  direction: ltr;
}

.cfInfo button {
  border: none;
  outline: none;
  background: #9891A5;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  transition: 0.5s;
  width: 75px;
  cursor: unset;
}

.cfInfo button.active {
  background: #1E0F3C;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.cancel-b {
  margin-top: 30px;
}

.offer_button {
  border-radius: 15px;
  min-width: 100px;
  font-size: 16px;
  line-height: 30px;
  padding: 0;
  background: var(--foundation-violet);
  color: var(--foundation-btn-color);
  font-family: var(--assistant);
}
.offer_button:hover {
  background: transparent !important;
  color: var(--text-black) !important;
  border-color: #000 !important;
}

.alingment {
  padding: 0 46px;
}

.filechoosen {
  position: absolute;
  width: 100%;
  height: 20%;
  opacity: 0;
  cursor: pointer;
}

.hd-react-select__control {
  min-height: 40px !important;
}

.hd-react-select__control .hd-react-select__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

.hd-react-select__single-value {
  overflow: visible !important;
  margin-right: 0px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hd-react-select__indicator {
  padding: 8px 2px !important;
}

.hd-react-select__value-container input {
  text-transform: uppercase;
}

.hd-react-select__value-container input::placeholder {
  text-transform: uppercase;
}

.time-react-select__indicators {
  display: none !important;
}

.time-react-select__control {
  min-height: 43px !important;
  font-size: 16px;
  font-weight: 500;
}

.time-react-select__value-container input {
  position: relative !important;
  left: -50px !important;
}

a.revisit-link {
  color: #18457d;
  font-size: 17px;
  font-weight: 500;
  text-decoration: underline !important;
  width: 75px;
  height: 24px;
  text-align: center;
}

span.revisit-link {
  text-align: center;
  max-width: 100px;
  width: 100%;
  color: #1E0F3C;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  border: 2px solid #1E0F3C;
  background: #fff;
  border-radius: 50px;
  display: block;
}

span.revisit-link:hover {
  background: #1E0F3C;
  color: #E9E7EC;
}

.create-appointment {
  border-top: 1px solid #000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 25px;
}

.create-appointment button {
  background: transparent;
  border: 2px solid #000;
  border-radius: 50px;
  color: var(--foundation-btn-color);
  cursor: pointer;
  font-family: var(--assistant);
  font-size: 22px;
  line-height: 32px;
  padding: 8px 0;
  text-align: center;
  width: 48%;
  font-weight: 600;
}

.create-appointment button:hover {
  background: #18457d;
  color: white;
}

.create-appointment button.new-appointment {
  background: var(--foundation-violet);
  color: var(--foundation-btn-color);
  cursor: pointer;
}

.create-appointment button.new-appointment:hover {
  color: var(--text-black);
  border: 2px solid #000;
  background: transparent;
}

li.updated-design .cfImgbg {
  width: 100%;
}

li.updated-design .doctorInfo {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0px 5px;
}
li.updated-design.idf-mode-meet {
  list-style: none;
}
li.updated-design.idf-mode-meet .doctorInfo {
  align-items: center;
  row-gap: 0px;
}
li.updated-design.idf-mode-meet .consultation-info {
  padding-top: 5px;
}

li.updated-design .doctorInfo h2 {
  margin: 0;
  line-height: 1;
  font-family: var(--assistant) !important;
  font-size: 26px;
  color: var(--text-primary);
}

li.updated-design .cfDate span {
  font-weight: 600;
}

li.updated-design .cfDay {
  gap: 5px;
}

li.updated-design .cfDate {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: unset;
  gap: 10px;
  justify-content: flex-start;
}

li.updated-design .cfDate h3 {
  margin: 0;
}

li.updated-design .consultation-info {
  display: flex;
  gap: 5px;
}
li.updated-design .consultation-info h4 {
  margin: 0;
  color: var(--text-primary);
  font-family: var(--roboto) !important;
  font-size: 16px;
}
.followup-text {
  font-weight: 600;
  font-size: 1.5rem;
  color: #18457d;
}

.cfInfo a {
  border: none;
  outline: none;
  background: #9891A5;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  width: 75px;
  text-align: center;
}

.cfInfo a:hover {
  color: #000 !important;
}

.cfInfo a.active {
  background: #1E0F3C;
  color: #fff;
}

.cfInfo a.active:hover {
  color: #000 !important;
}

.view-doc-pdf {
  margin: 10px 0px;
}

.rename-modal-body {
  padding: 40px 20px;
}

.rename-modal-body h3 {
  margin: 0;
  padding-bottom: 25px;
}

.rename-modal-body .form-control {
  max-width: 550px;
  width: 100%;
  margin: 0px auto;
  font-weight: 600;
}

.rename-modal-body .yes_no,
.confirmation-modal .yes_no {
  padding-top: 20px;
  justify-content: center;
  gap: 20px;
}

.rename-modal-body .document1,
.confirmation-modal .document1 {
  width: 20%;
  border-radius: 28px;
  color: var(--foundation-btn-color);
  font-family: Heebo !important;
  font-size: 16px;
  font-weight: 700;
  background: var(--foundation-violet);
}
.rename-modal-body .document1:hover,
.confirmation-modal .document1:hover {
  color: var(--foundation-btn-color);
  background: var(--foundation-violet);
  opacity: 0.8;
}

.futur-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.direct-link-grid {
  position: relative;
}

.direct-link-grid .btn {
  position: absolute;
  top: 3px;
  margin: 0;
  height: 37px;
  right: 3px;
  width: 102px;
  font-size: 18px;
}
.direct-link-grid .btn:hover {
  color: var(--text-black) !important;
}
.direct-link-grid i.las:hover {
  color: var(--text-black) !important;
}

.loader.consulting-loader {
  padding: 40px 0px;
  height: calc(100vh - 710px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: unset;
  transform: unset;
}

.consulting-no-result {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.consulting-no-result h6 {
  border: none;
  font-weight: 600;
}

.consulting-no-result h6:hover {
  background: transparent;
  color: #14427d;
}

.modal-confirmation .modal_card {
  height: 60%;
}
.cfInfo.patient-unavail {
  position: absolute;
  bottom: 0;
  margin: 0;
}
.cfInfo.patient-unavail button {
  max-width: 90px;
  width: 100%;
  padding: 6px 15px;
}
.loader-text {
  color: var(--text-primary);
  font-family: var(--roboto) !important;
  font-size: 24px;
}
/* ******************* Approved Modal ************************ */
.form-check-grid {
  padding: 40px 40px 20px 40px;
}
.form-check-grid .form-check .form-check-label {
  font-size: 24px;
  line-height: 25px;
  color: var(--text-primary);
  font-weight: 400;
  font-family: var(--assistant) !important;
  font-weight: bold;
}
.form-check-grid .form-check-input[type="checkbox"] {
  cursor: pointer;
  box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid #000;
  width: 18px;
  height: 18px;
  border-radius: 0;
  background-color: transparent;
}
.form-check-grid .form-check {
  margin-bottom: 15px;
}
.form-check-grid .form-check-input:checked[type="checkbox"] {
  background-color: #000;
}
.prescription__input-container input {
  min-width: 100px !important;
  text-align: right;
}
#transition-popper {
  z-index: 10;
}
.form-check-grid .form-check.idf-note .form-check-label {
  display: block;
  line-height: 32px;
}
.doc-right-list__menu, .doc-left-list__menu {
  margin: 0px;
}
.doc-right-list__control, .doc-right-list__option {
  text-align: right;
}
.doc-left-list__control, .doc-left-list__option {
  text-align: left;
}
.doc-right-list__indicator, .doc-left-list__indicator, .doc-right-list__indicator-separator, .doc-left-list__indicator-separator   {
  display: none !important;
}
.name-grid {
  display: flex;
  align-items: center;
}
.name-grid h6 {
  font-size: 14px;
  margin: 0px;
}
.name-grid span {
  display: inline-block;
  padding-right: 2px;
}
.name-grid p, .date-grid p {
  margin: 0;
}
.action-grid {
  display: flex;
}

.dropdown-action {
  display: none;
}
.add-new-rx {
  display: none;
}

@media (max-width: 1199px) {
  .dayhours {
    font-size: 28px;
  }
}

@media (max-width: 1099px) {
  .scheduleDate1 {
    flex-direction: row;
    align-items: center;
  }

  .filechoosen {
    position: absolute;
    width: 150px;
    height: 10%;
    opacity: 0;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .cfImgmain img {
    width: 100px;
    height: 100px;
  }

  .buttonInfo button {
    font-size: large;
  }

  .scheduleDate1 {
    flex-direction: row;
    align-items: center;
  }
  .doctor-dashboard.statusWrapper .innerWrapper {
    gap: 8px;
  }
}

@media (max-width: 991px) {

  .sortshiftwidth {
    width: 57% !important;
  }

  .cfDate h3 {
    font-size: 32px;
    line-height: 42px;
  }

  .textchange h3 {
    font-size: 16px;
  }

  .textchange h2 {
    font-size: 20px;
  }

  .textDiv {
    font-size: 16px;
  }

  .cfImgTxt h2 {
    font-size: 30px;
    font-weight: 700;
  }

  .cfImgmain {
    padding-right: 14px;
  }

  .cfFirst {
    width: 53%;
  }

  .cfFirst1 {
    width: 32%;
  }

  .cflast {
    width: 43%;
  }

  .scheduleDate {
    display: flex;
    align-items: center;
    width: 108%;
    min-width: 0 !important;
    padding: 0;
  }

  .buttonInfo {
    margin: 0 -1px;
  }

  .sizeCorr h2 {
    font-size: 20px;
  }

  .abc span {
    font-size: 17px;
  }

  .cfInfo1 h3 {
    font-size: 17px;
  }

  .cfInfo1 h2 {
    font-size: 20px;
  }

  .statusWrapper .innerWrapper .itemBoxHeb h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .statusWrapper .innerWrapper .itemBoxHeb h2 span {
    font-size: 25px;
  }

  .statusWrapper .innerWrapper .itemBoxEng h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .statusWrapper .innerWrapper .itemBoxEng h2 span {
    font-size: 25px;
  }

  .buttonInfo button {
    font-size: smaller;
  }

  .statusWrapper .innerWrapper .itemBoxHeb {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 35px 0px;
    height: 120px;
    justify-content: center;
  }

  .statusWrapper .innerWrapper .itemBoxEng {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 25px 0px;
    height: 120px;
    justify-content: center;
  }

  .statusWrapper .innerWrapper .itemBoxHeb img {
    position: static;
  }

  .userInnerWrapper {
    padding: 20px;
  }

  li.updated-design .cfImgmain {
    padding: 0px;
    padding-left: 15px;
  }
  .form-check-grid .form-check .form-check-label {
    font-size: 22px;
    line-height: 1.2;
  }
  .statusWrapper .container, .userWrapper .container {
    padding: 0px 15px !important;
  }
  .cfInfo.doc-w-summary {
    display: none;
  }
  .only-rx-doc .statusWrapper.doctor-dashboard {
    padding: 0;
  }
.adminDataTable .ag-cell-value,  .adminDataTable .ag-header-cell {
    padding: 0px 10px;
  }
 .name-grid {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center !important;
    height: 100%;
  }
 .name-grid h6 {
    font-weight: 700;
  }
  .name-grid p,  .date-grid p  {
    line-height: normal;
  }
 .name-grid span {
    display: none;
  }
 .doctor-user .adminDataTable {
    padding: 24px 0px;
    background: #f1f1f1;
  }
.ag-body-viewport::-webkit-scrollbar {
    width: 4px;
  }
 .ag-row {
    border: none;
  }
  .date-grid {
    display: flex;
    height: 100%;
    align-items: center;
   
  }
  :dir(rtl) {
    .date-grid {
      direction: ltr;
      justify-content: flex-end;
    }
   .name-grid {
      align-items: flex-end;
    }
  }
  .date-grid p {
    font-weight: 600;
  }
  .action-grid {
    display: none;
  }

  .dropdown-action {
    display: block;
    position: relative;
    height: 100%;
  }
  .add-new-rx {
    background: var(--foundation-violet);
    border-color: var(--foundation-violet);
    font-family: var(--assistant);
    color: #fff;
    position: fixed;
    text-align: center;
    transition: all .5s;
    width: 48px;
    height: 48px;
    z-index: 99;
    right: 15px;
    bottom: 10px;
    border-radius: 50px;
    display: block;
  }
  .add-new-rx a {
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .add-new-rx a svg path {
    fill: #fff;
  }
  .add-new-rx a:hover {
    color: #fff;
  }

  .mobile-action-menu {
    font-size: 14px;
    margin: 0;
    border-radius: 4px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 130px;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0px;
  }
  .dropdown-action .btn-primary {
    background: transparent;
    border: none !important;
    color: var(--foundation-violet);
    padding: 0;
    border-radius: 0;
    height: 100%;
  }
  .dropdown-action .btn-primary:hover, .dropdown-action .btn-primary:focus, .dropdown-action .btn-primary:active {
    background: transparent !important;
    color: var(--foundation-violet);
  }
  .mobile-action-menu .btn-primary {
    width: 100%;
    margin: 0px !important;
    border-radius: 0;
    height: 30px;
    padding: 0px;
    background: transparent;
    border: none;
    color: var(--foundation-violet);
    font-weight: 700;
  }
  .rx-doctor .ag-root-wrapper {
    border: none;
  }
  .rx-doctor .ag-theme-alpine {
    height: 400px !important;
  }
  .only-rx-doc .rx-doctor .ag-theme-alpine {
    height: 450px !important;
  }
  
}

@media (max-width: 767px) {
  .cfImgmain img {
    width: 90px;
    height: 90px;
  }
  .adminDataTable {
    padding-bottom: 20%;
  }

  .toggletab {
    display: block;
  }

  .toggleContent {
    display: none;
  }

  .drDate {
    background: #f1f1f1;
    border-radius: 20px;
    padding: 25px 10px;
    display: flex;
    justify-content: center;
    width: 250px !important;
    margin-top: 10px;
  }
  .support_text {
    font-size: 16px;
  }

  .buttton_content {
    min-width: 100px;
  }

  .scheduleDate1 {
    flex-direction: column;
  }

  .offerMeeting {
    width: 250px !important;
  }

  .requestdate {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px !important;
    padding: 25px 0px 0px;
  }

  .datebox {
    background: #f1f1f1;
    border-radius: 20px;
    padding: 25px 10px 25px 25px;
    width: 250px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .maincomplaint::after,
  .maincomplaint::before {
    display: none;
  }

  .maincomplaint {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .maincomplaint h4 {
    font-size: 24px;
    text-transform: capitalize;
    margin-bottom: 5px;
    color: gray;
    font-weight: bold;
  }

  .maincomplaint h2 {
    font-size: 30px;
    font-weight: 700;
  }

  .cfBase {
    flex-direction: column;
  }

  .textchange h3 {
    font-size: 16px;
  }

  .cfInfo1 h3 {
    font-size: 18px;
  }

  .cfInfo1 h2 {
    font-size: 22px;
  }

  .buttonInfo button {
    font-size: large;
  }

  .cfBase1 {
    flex-direction: column;
    text-align: center;
  }

  .cflast {
    width: 100%;
    margin-top: 20px;
  }

  .cfFirst {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }

  .cfFirst1 {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }

  .cfinfo1 {
    flex-direction: column;
    text-align: center;
  }

  .scheduleDate {
    display: block;
    text-align: center;
    width: 100%;
  }

  .payButton {
    width: 60%;
    padding: 25px 0px 0px 0px;
  }

  .cfDate {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    margin: 0;
    height: unset;
  }

  .followup-text {
    margin: 0;
  }

  .cfDate::before {
    position: absolute;
    content: "";
    z-index: 0;
    width: 25%;
    left: 0;
    top: 21px;
    height: 1px;
    background-color: #ccc;
    display: none;
  }

  .cfDate::after {
    position: absolute;
    content: "";
    z-index: 0;
    width: 25%;
    right: 0;
    top: 21px;
    height: 1px;
    background-color: #ccc;
    display: none;
  }

  .cfDate h3 {
    margin: 0;
    font-size: 16px;
  }

  .cfDate span {
    font-size: 16px;
    font-weight: bold;
    padding: 0 5px 0 10px;
  }
  li.updated-design .doctorInfo h2 {
    font-size: 19px;
  }

  .statusWrapper .innerWrapper .itemBoxHeb h2 span {
    font-size: 20px;
  }

  .statusWrapper .innerWrapper .itemBoxEng h2 span {
    font-size: 20px;
  }

  .textDiv {
    font-size: 18px;
  }

  .textchange h2 {
    font-size: 14px;
  }
  .summarytext h3 {
    font-size: 20px;
    margin: 0;
  }

  .cancel-b {
    width: 120px;
  }

  .cfImgTxt {
    text-align: center;
  }

  .adult {
    display: block;
  }

  .adult h3 {
    font-size: 22px;
    text-transform: capitalize;
    margin-bottom: 5px;
    color: gray;
    font-weight: bold;
  }

  .adult h2 {
    font-size: 26px;
    font-weight: 700;
    color: #1E0F3C;
  }
  .datetimeshift {
    font-size: 24px;
  }

  .create-appointment button {
    width: 90%;
    font-weight: 500;
    font-size: 16px;
  }

  li.updated-design .cfDate {
    gap: 6px;
    border: none;
  }
  li.updated-design.idf-mode-meet .cfDate {
    width: unset;
  }
  li.updated-design.idf-mode-meet .cfDate h3 {
    line-height: 26px;
  }

  li.updated-design .cfDate span {
    padding: 0 0px 0 5px;
    font-size: 16px;
  }

  li.updated-design .doctorInfo {
    row-gap: 5px;
    padding: 0;
  }

  .rename-modal-body {
    padding: 20px 0;
  }

  .rename-modal-body .yes_no,
  .confirmation-modal .yes_no {
    gap: 10px;
  }

  .rename-modal-body .document1,
  .confirmation-modal .document1 {
    width: 48%;
  }
  .cfInfo.patient-unavail {
    width: 100%;
    right: -50%;
    transform: translate(-50%, 0px);
    justify-content: center;
  }
  .cfInfo.patient-unavail button {
    max-width: 120px;
    width: 100%;
    padding: 8px 15px;
  }
  .statusWrapper {
    padding-top: 40px;
  }
  .iframeclose.payment {
    margin: 15px auto;
  }
  .form-check-grid .form-check .form-check-label {
    font-size: 19px;
  }
  .form-check-grid .form-check.idf-note .form-check-label {
    font-size: 18px;
    line-height: 28px;
  }
  .form-check-grid {
    padding: 20px 20px 0px 0px;
  }
}

@media (max-width: 480px) {
  .grecaptcha-badge {
    bottom: 10px !important;
  }

  .filechoosen {
    position: absolute;
    width: 125px;
    height: 10%;
    opacity: 0;
    cursor: pointer;
  }

  .spanActive {
    font-size: 14px;
  }

  .toggle {
    font-size: 14px;
  }

  .statusWrapper .innerWrapper .itemBoxEng h2 strong {
    display: block;
    font-weight: bold;
    line-height: 28px;
    font-family: "almoni-demibold";
  }
  .userInnerWrapper ul li {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .leftborder {
    border-left: none;
  }

  .cfDate::before,
  .cfDate::after {
    width: 15%;
  }

  .cancel-b {
    width: 100px;
  }

  .cfInfo button {
    padding: 3px 10px;
  }
  .cfInfo a {
    padding: 3px 10px;
  }

  .cfChk h3 {
    font-size: 18px;
  }

  .cfImgTxt h3 {
    font-size: 22px;
  }

  .cfImgTxt h2 {
    font-size: 26px;
  }

  .cfImgTxt h4 {
    font-size: 20px;
  }

  .maincomplaint h2 {
    font-size: 24px;
  }

  .asap {
    font-size: 28px;
  }

  .showday {
    font-size: 28px;
  }

  .showdayHeb {
    font-size: 28px;
  }

  .showdayEng {
    font-size: 28px;
  }

  .dayhours {
    font-size: 28px;
  }

  .offer_button {
    font-size: 20px;
  }

  li.updated-design .cfDate::before,
  li.updated-design .cfDate::after {
    display: none;
  }
}

@media (max-width: 360px) {

  .cfDate::before,
  .cfDate::after {
    width: 10%;
  }

  .textDiv {
    font-size: 13px;
  }

  .textchange h3 {
    font-size: 13px;
  }

  .userInnerWrapper {
    padding: 15px 10px;
  }
}