@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 72px);
}
.newRegd {
  max-width: 600px;
  width: 100%;
  font-family: var(--assistant) !important;
}
.newRegd form {
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
}

.signTop {
  position: relative;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px !important;
}
.idf-auth .signTop {
  padding: 0;
  margin: 0px !important;
}
.phone_number {
  direction: ltr !important;
}
.id_container {
  border-radius: 50px;
  box-shadow: none !important;
  margin: 0 0 15px 0;
  border: 2px solid #000;
  padding: 5px 10px;
  height: 45px;
  font-family: var(--assistant);
  font-size: 18px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  text-align: center;
}
input[type="number"]:focus {
  border: 2px solid #14427d;
}
.signTop img {
  width: 30px;
}

.signTop h2 {
  font-family: var(--assistant) !important;
  color: #1E0F3C;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 36px;
  line-height: 42px;
  text-transform: inherit;
}

.signTopTxt {
  text-align: right;
}

.signTopTxt h3 {
  font-family: "Roboto Condensed", sans-serif;
  color: #14427d;
  font-weight: 700;
  margin: 0;
  padding: 0 0 5px 0;
  font-size: 30px;
  line-height: 38px;
  text-transform: inherit;
}

.signTopTxt p {
  font-family: "Roboto Condensed", sans-serif;
  color: #14427d;
  font-weight: 300;
  margin: 0;
  padding: 0;
  font-size: 23px;
  line-height: 30px;
  text-transform: inherit;
}

.newRegd input[type="text"],
.newRegd input[type="email"],
.newRegd input[type="password"],
.newRegd input[type="number"] {
  border-radius: 50px;
  box-shadow: none !important;
  margin: 0 0 15px 0;
  border: 2px solid #000;
  padding: 5px 10px;
  height: 45px;
  font-family: var(--assistant);
  font-size: 18px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  text-align: center;
  direction: ltr !important;
}

.newRegd button[type="submit"] {
  border-radius: 50px;
  box-shadow: none !important;
  margin: 0 0 10px 0;
  border: 2px solid var(--foundation-violet);
  padding: 5px 10px;
  height: 45px;
  font-family: var(--assistant);
  font-size: 18px;
  line-height: 22px;
  color: var(--foundation-btn-color);
  font-weight: 700;
  width: 100%;
  background: var(--foundation-violet);
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 25px;
}
.MuiGrid-grid-xs-true {
  direction: ltr;
}
.errorsmg {
  color: #fb0000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--assistant);
}
.MuiPaper-root {
  border-radius: 25px;
  border: 4px solid #000;
}
.MuiTypography-h4 {
  padding: 12px;
  font-weight: bold !important;
  color: #000;
  font-size: 34px !important;
  line-height: 34px !important;
  font-family: var(--assistant) !important;
}
.MuiTypography-h6 {
  font-size: 22px !important;
  font-family: var(--assistant) !important;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: #000;
}
.submit {
  font-size: 16px;
  font-family: var(--assistant);
  font-weight: bold;
  text-transform: uppercase;
  background: var(--foundation-violet);
  color: #fff;
  padding: 10px 45px 10px 45px;
  border-radius: 28px;
}
.submit:hover {
  background: var(--foundation-violet) !important;
}

.newRegd button[type="submit"]:hover {
  background: #fff;
  color: #000;
}

.hlink {
  text-align: center;
  margin-bottom: 10px;
}

.hlink a {
  color: var(--text-black);
  font-family: var(--assistant);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .36px;
  line-height: 150%;
  padding-top: 24px;
  text-align: center;
  text-decoration: underline!important;
}

.hlink a:hover {
  color: #000 !important;
}

.lastDiv {
  background-color: #E9E7EC;
  border-radius: 999px;
  box-shadow: 0 3px 6px #ccc;
  font-size: 30px;
  text-align: center;
  padding: 15px;
  border: 2px solid #ccc;
  cursor: pointer;
  color: #000;
  font-family: var(--assistant);
}

.lastDiv a {
  color: #000 !important;
}

.lastDiv a:hover {
  background-color: #f1f1f1;
  color: #000 !important;
}

.paper {
  padding: "30px";
  margin: "30px auto";
}
.input_font {
  font-size: 25px !important;
  font-family: almoni-demibold, sans-serif !important;
}

.otpButton {
  background: #e12c4b;
  color: white;
  padding: 10px 45px 10px 45px;
  border-radius: 10px;
}

.modalDiv {
  display: flex;
  align-items: center;
}

.modalDiv .MuiPaper-root {
  padding: 20px;
}

.modalDiv h4 {
  font-size: 29px;
  margin: 0;
}

.user_toggle {
  display: flex;
  margin: 10px;
  justify-content: space-around;
}

.loginToggle {
  font-size: 20px;
  border-bottom: 1px solid var(--foundation-violet);
  margin: 0 0 30px;
  padding: 0 0 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.loginToggle > span {
  padding: 5px;
  font-size: 21px;
  text-align: center;
  border: 2px solid #000;
  border-radius: 90px;
  cursor: pointer;
  color: var(--text-black);
  font-family: var(--assistant);
}
.loginToggle > span.active-tab {
  color:  var(--foundation-btn-color) ;
  background: var(--foundation-violet);
  font-weight: 600;
}
.google-link h6 {
  font-size: 14px;
  text-align: center;
  direction: ltr;
  color: #685E7C;
  font-family: var(--assistant) !important;
}
@media (max-width: 991px) {
  .newRegd {
    padding: 50px 24px 20px 24px;
  }
  .lastDiv {
    font-size: 18px;
  }
  .MuiTypography-h4 {
    padding: 12px !important;
    text-align: center;
  }

  .MuiGrid-grid-xs-true {
    display: flex;
    direction: ltr;
    align-items: center !important;
  }

  button {
    padding: 10px 23px;
  }

  .MuiTypography-h6 {
    font-size: 20px !important;
    text-align: center;
  }
}

@media (max-width: 767px) {

  .lastDiv {
    font-size: 18px;
  }
  .MuiTypography-h4 {
    padding: 12px !important;
    text-align: center;
    font-size: 26px !important;
  }

  .MuiGrid-grid-xs-true {
    display: flex;
    direction: ltr;
    align-items: center !important;
  }

  button {
    padding: 10px 23px;
  }

  .MuiTypography-h6 {
    font-size: 18px !important;
    text-align: center;
  }
  .signTop h2 {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  .MuiTypography-h4 {
    font-size: 26px !important;
    padding: 12px !important;
    text-align: center;
  }
  .modalDiv {
    width: 80% !important;
  }

  .lastDiv {
    font-size: 23px;
    font-weight: bold;
  }

  .MuiGrid-grid-xs-true {
    display: flex;
    direction: ltr;
    align-items: center !important;
  }

  button {
    padding: 10px 23px;
  }

  .MuiTypography-h6 {
    font-size: 22px !important;
    text-align: center;
  }
}

@media (max-width: 320px) {
  .newRegd {
    padding-top: 140px;
   }  
  .lastDiv {
    font-size: 23px;
    font-weight: bold;
  }
}
