@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

.signTop {
  position: relative;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.phone_number {
  direction: ltr !important;
}

.signTop img {
  width: 30px;
}

.signTopTxt {
  text-align: right;
}

.signTopTxt h3 {
  font-family: "Roboto Condensed", sans-serif;
  color: #14427d;
  font-weight: 700;
  margin: 0;
  padding: 0 0 5px 0;
  font-size: 30px;
  line-height: 38px;
  text-transform: inherit;
}

.signTopTxt p {
  font-family: "Roboto Condensed", sans-serif;
  color: #14427d;
  font-weight: 300;
  margin: 0;
  padding: 0;
  font-size: 23px;
  line-height: 30px;
  text-transform: inherit;
}

/* .errorsmg {
  color: #fb0000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
} */


.captchaContainer {
  display: flex;
  justify-content: center;
}


.google-recptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.modalDiv {
  display: flex;
  align-items: center;
}
.modalDiv .MuiPaper-root {
  padding: 20px;
}
.modalDiv h4 {
  font-size: 29px;
  margin: 0;
}
.check {
  width: 100%;
  display: block;
}
.searchBox {
  height: 0px !important;
  width: 0px !important;
}
.submit_button {
  margin-bottom: 4%;
}
.check span {
  font-size: 14px;
  line-height: 25px;
  color: #000;
  font-weight: 600;
  font-family: var(--assistant);
}
.searchWrapper {
  border: none !important;
}
.doctordiv {
  text-align: center;
}
.title {
  border-radius: 35px;
  padding: 10px;
  border: 2px solid #14427d;
  text-align: center;
  color: gray;
}
.title ::after {
  border: 2px solid #14427d;
}
.contact-form-content h4 {
  color: #14427d;
  font-weight: bold;
}
.contact-form-content p {
  font-size: 20px;
}
.joining {
  color: #14427d;
  font-weight: bold;
  padding: 10px 0;
}
.speciality {
  border-radius: 35px !important;
  line-height: 37px !important ;
}
.regbutton {
  background: #14427d;
  border-radius: 33px;
  border-color: #14427d;
  width: 29%;
  height: 47px;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-top: 10px;
  box-shadow: none !important;
  transition: all 0.5s;
  position: relative;
  margin-bottom: 20px;
}
.regbutton :hover {
  background: #14427d;
}
.doctor-signup-content h3 {
  color: #1e0f3c;
  font-family: var(--assistant);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 3.36px;
  line-height: 46px;
  margin: 0;
  padding-bottom: 40px;
  text-align: center;
}
.doctor-signup-content h4 {
  color: var(--text-black);
  font-size: 22px;
  font-weight: 700;
  letter-spacing: .96px;
  line-height: 140%;
  font-family: var(--assistant);
  font-style: normal;
  margin: 0;
}
.doctor-signup-content p {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  font-family: var(--assistant);
  font-style: normal;
  margin: 0;
  padding-bottom: 20px;
}
.doctor-signup-content .regbutton, .doctor-signup-form .submit_button {
  border-radius: 4px;
  background: #1E0F3C;
  max-width: 148px;
  width: 100%;
  height: 48px;
  color: #FFF;
  font-family: var(--assistant);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: unset;
  margin: 0;
  border: none;
}
.doctor-signup-content .regbutton:hover, .doctor-signup-form .submit_button:hover {
  background: var(--foundation-violet);
  color: var(--foundation-btn-color);
  border: none;
  box-shadow: none !important;
  outline: 0;
}
.doctor-signup-form .form {
  padding: 30px 0px;
}
.doctor-signup-form .form-label {
  color: var(--text-black);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 8px;
}
.doctor-signup-form .form-control, .doctor-signup-form .form-label {
  font-family: var(--assistant);
  font-style: normal;
  line-height: 150%;
}
.doctor-signup-form .form-control {
  background: #fff;
  border: 1px solid var(--text-black);
  border-radius: 0;
  color: #505050;
  font-size: 18px;
  font-weight: 400;
  min-height: 48px;
  padding: 10px 12px;
  width: 100%;
}
.doctor-signup-form .firstname-form, .doctor-signup-form .lastname-form {
  padding-bottom: 24px;
}
.doctor-signup-content .hlink {
  padding-top: 24px;
}
.doctor-signup-content .hlink a {
  padding: 0;
}
.error-input .form-control {
  border-color: #fb0000;
}
@media (max-width: 768px) { 
  .doctor-signup-content h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 1.92px;
  }
  .doctor-signup-content p {
    padding-bottom: 10px;
  }
  .doctor-signup-form .form {
    padding: 20px 0px;
  }
  .doctor-signup-form .firstname-form, .doctor-signup-form .lastname-form {
    padding-bottom: 15px;
  }
}
