@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Dancing+Script&family=Raleway:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: "almoni-demibold";
  font-style: normal;
  src: url("./assets/fonts/almoni-tzar-demibold-aaa.woff2") format("woff2");
}

@font-face {
  font-family: "almoni-light";
  font-style: normal;
  src: url("./assets/fonts/almoni-tzar-light-aaa.woff2") format("woff");
}
:root {
  --primary-bg: #FFF;
  --foundation-violet: #1E0F3C;
  --foundation-btn-color: #E9E7EC;
  --text-primary: #1E0F3C;
  --text-black: #000;
  --placeholder-color: #000;
  --roboto: "Roboto", sans-serif;
  --assistant: "Assistant", sans-serif;
}

body {
  font-family: almoni-light, sans-serif !important;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  overflow-x: hidden;
}

body[dir="rtl"] .navbar .dropdown-menu {
  left: inherit;
  right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  
  font-family: var(--assistant);
  color: var(--text-primary);
}

.cursive-font {
  font-family: "Dancing Script", cursive !important;
}

.container {
  max-width: 1440px !important;
  padding: 0 64px !important;
}
.hide {
  display: none;
}
.noification_check {
  height: 20px;
  width: 20px;
}
.phone_number {
  direction: ltr !important;
}

.myDIV:hover + .hide {
  display: block;
  color: #14427d;
}
select {
  font-size: 22px !important;
  line-height: 21px !important;
  padding: 10px 20px !important;
  color: #14427d;
}

.hide-layout .header,
.hide-layout .footer {
  display: none;
}

.content {
  padding: 80px 0px;
}

.white-layout .content {
  padding-top: 0px;
}

.main-layout.rtl-layout {
  text-align: right;
}

input[type="text"] {
  direction: rtl;
  text-align: center;
  font-family: var(--assistant) !important;
  color: #000;
}

input:focus,
.form-select:focus,
.form-control:focus {
  box-shadow: none !important;
}
input::placeholder {
  color: #000;
}
.border-btn {
  background: transparent !important;
  border-radius: 4px !important;
  border: 2px solid var(--foundation-violet) !important;
  padding: 16px !important;
  width: 100%;
  height: 48px;
  color: var(--foundation-violet) !important;
  font-family: var(--assistant) !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 150% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}
.black-btn {
  padding: 12px 40px !important;
  border-radius: 4px !important;
  background: var(--foundation-violet) !important;
  color: var(--foundation-btn-color) !important;
  font-family: var(--assistant) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  height: 48px;
  width: 100%;
  text-align: center !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none !important;
}

/************************************************************************************************************/

/*************** Media Query ******************/

/************************************************************************************************************/

@media screen and (max-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px !important;
  }
}

@media screen and (max-width: 991px) {
  .container {
    padding: 0 24px!important;
  }
  .content {
    padding: 40px 0 50px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 720px !important;
  }

  .content {
    padding: 30px 0 50px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 540px !important;
  }
}

@media screen and (max-width: 480px) {
  .container {
    max-width: 100% !important;
  }

  .content {
    padding: 30px 0 40px;
  }
}

.forceLtrInput {
  direction: ltr !important;
}

.rtl {
  direction: rtl !important;
  text-align: right;
}
