.thank-you-page {
  position: relative;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thank-you-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  background-size: cover;
  background-position: center;
}


.sent_invite {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  gap: 5px;
}

.sent_invite button {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  max-width: 278px;
  width: 100%;
  height: 59px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.sent_image {
  height: 17px;
  width: 17px;
  margin-left: 10px;
}

.thank-you-grid {
  background: #ffffff;
  /* background: linear-gradient(180deg, #18457d 20%, #86d7fb 100%, #042854 20%); */
  /* box-shadow: 0px 1px 20px #042854;
    /* box-shadow: 0px 24.5184px 32.0625px rgb(0 0 0 / 10%); */
  border-radius: 20px;
  max-width: 1000px;
  width: 100%;
  position: relative;
  z-index: 9;
  padding-top: 40px;
}

.thankYouMeeting {
  padding: 140px 0px;
}
.thankYouMeeting-content {
  text-align: center;
}
.thankYouMeeting-content h2 {
  color: #1E0F3C;
  text-align: center;
  font-family: var(--assistant);
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 3.36px;
  margin: 0px auto;
  padding-top: 6px;
  padding-bottom: 10px;
  max-width: 313px;
  width: 100%;
}
.thankYouMeeting-content h2 span {
  font-weight: 700;
}
.thankYouMeeting-content p {
  color: var(--text-black);
  text-align: center;
  font-family: var(--assistant);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
}

.thank-you-social ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.thank-you-social {
  padding-top: 50px;
}

.thank-you-social li {
  margin: 0px 2px;
}

.thank-you-social li img,
.thank-you-social li svg {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.thank-you-social li.facebook svg path {
  fill: #3a5795;
}

.thank-you-social li.linkedin svg {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  padding: 1px;
  background: linear-gradient(to bottom, #3a5795 0%, #2b4886 100%);
}

.thank-you-social li.instagram svg {
  border-radius: 20px;
  background: radial-gradient(circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%);
}

.viewpending {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
}
.viewpending button {
  max-width: 194px;
  width: 100%;
  height: 48px;
  color: #FFF;
  font-family: var(--assistant);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.thank-you-social p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 0;
  padding-bottom: 18px;
}

.thank-you-content {
  text-align: center;
}

.thank-you-content svg,
.thank-you-content img {
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: auto;
}

.thank-you-content h2 {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 40px;
  font-weight: 600;
  font-size: 55px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1E0F3C;
}

.thank-you-content p {
  padding-bottom: 15px;
  text-align: center;
  max-width: 420px;
  margin: 0px auto;
  color:  #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
  letter-spacing: 0.36px;
}

.thank-you-link a {
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
  color: #f35c5c;
  margin: 0px 15px;
  display: inline-block;
}

.home-btn {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.home-btn button,
.home-btn button:hover,
.home-btn button:focus,
.home-btn button:focus-within,
.home-btn a,
.home-btn a:hover,
.home-btn a:visited,
.home-btn a:active,
.home-btn a:focus {
  background-color: #11437e;
  border-color: #11437e;
  font-size: 22px;
  line-height: 1.2;
  border-radius: 30px;
  width: 120px;
  height: 46px;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.home-btn a:hover,
.home-btn button:hover {
  background: #042854;
  color: #fff !important;
}

.contac-section {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: fit-content;
  right: 12px;
  bottom: -13px;
  position: absolute;
}

.contac-section p {
  font-weight: 500;
  color: #000;
}

.contac-section a {
  color: #042854 !important;
  font-weight: 600;
}

.successText {
  color: #18457d;
}

.successText h5 {
  font-size: 24px;
  text-align: center;
  max-width: 80%;
  margin: 0px auto;
  color: #000;
}

@media screen and (max-width: 767px) {
  .thank-you-page {
    align-items: flex-start;
  }
  .thankYouMeeting {
    padding: 80px 0px;
  }
  .thankYouMeeting-content h2 {
    font-size: 24px;
    letter-spacing: 1.92px;
    max-width: 212px;
    padding-top: 16px;
    padding-bottom: 4px;
  }
  .thankYouMeeting-content h2 span {
    font-weight: 500;
  }
  .thankYouMeeting-content p {
    font-size: 16px;
  }
  .viewpending {
    padding-top: 10px;
  }

  .thank-you-grid {
    padding: 30px 30px 34px;
  }

  .thank-you-content h2 {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 34px;
  }
  .viewpending button {
    max-width: 160px;
    font-size: 16px;
    height: 50px;
  }

  .thank-you-content p {
    padding-bottom: 20px;
    max-width: 300px;
    font-size: 18px;
    font-weight: 500;
  }

  .thank-you-social {
    padding-top: 20px;
  }

  .thank-you-social li img,
  .thank-you-social li svg {
    width: 37px;
    height: 37px;
    object-fit: cover;
  }

  .successText h5 {
    font-size: 20px;
    font-weight: 500;
    max-width: 100%;
  }
}