.modal-alert .modal-dialog {
    position: absolute;
    bottom: 10px;
    width: 100%;
    transform: translateX(-50%) !important;
    left: 50%;
}
.modal-alert .modal-body {
    padding: 0;
}
.modal-alert .modal-content {
    border-radius: 8px;
    background: #FFF;
    padding: 48px 24px;
    border: 6px solid #E9E7EC;
}
.modal-alert-content h6 {
    color: var(--foundation-violet-violet-300, #685E7C);
    font-family: var(--assistant);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 530px;
    margin: 0px auto;
    text-align: right;
    direction: rtl;
}
.modal-alert .modal-footer {
    border: none;
    justify-content: center;
    gap: 40px;
    padding: 0;
    padding-top: 48px;
}
.modal-alert .modal-footer .border-btn {
    max-width: 165px;
}

@media screen and (max-width: 991px) {
    .modal-alert-content h6 {
        font-size: 18px;
    }
    .modal-alert .modal-dialog {
        margin: 0px auto;
        max-width: 90%;
        bottom: 30px;
    }
    .modal-alert .modal-content {
        padding: 24px 12px;
    }
    .modal-alert .modal-footer {
        padding-top: 20px;
        gap: 15px;
    }
}