.support-chat {
    background: transparent;
    color: #fff;
    position: fixed;
    text-align: center;
    transition: all .5s;
    width: 90px;
    height: 90px;
    z-index: 9;
    left: 40px;
    bottom: 15px;
  }

  .support-chat .btn-primary {
    padding: 0;
    background: transparent;
    border: none;
  }
  .support-chat img {
    width: 90px;
    height: 90px;
  }
  .support-chat .btn-primary:hover{
    background: transparent !important;
  }
  .support-chat .btn:first-child:active {
    background: transparent !important;
    border-color: transparent !important;
  }
  body.show-enable-toolbar .support-chat {
    bottom: 250px;
    transition: all .5s;
  }
  .support-chat a img {
    width: 100%;
  }
  .floating { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  @keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 8px); }
    100%   { transform: translate(0, -0px); }   
  }
  .support-modal {
    bottom: 70px;
    color: #fff;
    position: fixed;
    text-align: center;
    transition: all .5s;
    max-width: 204px;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.16);
    padding: 16px;
    z-index: 9;
    left: 140px;
    border-radius: 16px 16px 16px 0px;
  }
 
  .support-title h6 {
    color: #685E7C;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    padding-bottom: 4px;
    font-family: var(--assistant);
  }
  .support-title p {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    font-family: var(--assistant);
  }
  .support-grid {
    display: flex;
    gap: 32px;
    padding-top: 16px;
    direction: ltr;
  }
  .support-grid span {
    cursor: pointer;
  }
  .alert-modal-grid {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .alert-modal-grid h6 {
    margin: 0;
    font-size: 20px;
    color: #1e0f3c;
    font-family: var(--assistant);
    font-weight: 500;
  }
  .alert-modal-grid a {
    margin: 0;
    font-size: 20px;
    color: #1e0f3c;
    font-family: var(--assistant);
    font-weight: 600;
    text-decoration: underline !important;
  }
  .alert-modal-grid a:hover {
    color: #1e0f3c !important;
  }
  .mobile-view {
    display: none;
  }
  body.trigger-position-left #enable-toolbar-trigger {
    left: 5px !important;
    top: 0px !important;
  }
  
  /* for mobile */
@media screen and (max-width: 991px) { 
    .support-chat {
      width: 60px;
      height: 60px;
      left: 25px;
    }
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: block;
    }
    .support-modal {
      left: 85px;
    }
    .support-chat img {
      width: 60px;
      height: 60px;
    }
  }
  @media screen and (max-width: 767px) {
    .support-chat {
      width: 50px;
      height: 50px;
      left: 25px;
    }

    .support-modal {
      left: 85px;
    }
    .support-chat img {
      width: 50px;
      height: 50px;
    }
    body.show-enable-toolbar .support-chat {
      bottom: 110px; 
    }
  }